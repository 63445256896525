import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";
import moment from "moment";


export function ProductCheckerChart(props) {
    const {data} = props;

    return (
        <ResponsiveContainer width="100%" height={230}>
            <BarChart data={data} margin={{top: 10, right: 5, left: 5, bottom: 0}}>
                <XAxis dataKey="date" stroke="#d9d9d9" tickFormatter={(tickItem) => moment(tickItem).format('DD.MM.YY')} />
                <YAxis yAxisId="order" stroke="#5999d4" allowDecimals={false}/>
                <Tooltip wrapperStyle={{color: '#3a6168'}}/>
                <Bar name="Bestellungen" yAxisId="order" dataKey="order" fill="#5999d4"/>
            </BarChart>
        </ResponsiveContainer>
    );
}
