import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import MaterialTable from "@material-table/core";

function CaseTable({title, caseData, casesLoaded, classes}) {
    return (
        <>
            {!casesLoaded && (
                <Box className={classes.progressContainer}>
                    <CircularProgress className={classes.progress}/>
                </Box>
            )}
            {casesLoaded && (
                <>
                    <MaterialTable
                        title={title}
                        columns={[
                            {
                                title: 'Erstattung vom',
                                field: 'date',
                                render: (rowData) => <>{ moment(rowData.date).format('DD.MM.YYYY') }</>,
                            },
                            {
                                title: 'Amazon-Fallnummer',
                                field: 'caseId',
                                render: (rowData) => <a className={classes.link} href={`https://sellercentral.amazon.de/cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${rowData.caseId}&View+or+Respond=Senden`} target="_blank" rel="noopener noreferrer">{rowData.caseId}</a>
                            },
                            {
                                title: 'Betrag',
                                field: 'reimbursementSum',
                                render: (rowData) => (
                                    <>
                                        { rowData.reimbursementSum.toFixed(2) }
                                        €
                                    </>
                                ),
                            },
                            {
                                title: 'Korrekturen',
                                render: (rowData) => (
                                    <>
                                        { rowData.reversalSum.toFixed(2) }
                                        €
                                    </>
                                ),
                            },
                            {
                                title: 'Abrechnung',
                                render: (rowData) => {
                                    let diff = (rowData.reimbursementSum + rowData.reversalSum).toFixed(2);
                                    return (
                                        <>
                                            { diff }
                                            €
                                        </>
                                    )
                                }
                            }
                        ]}
                        data={caseData}
                        options={{
                            pageSize: 50,
                        }}
                    />
                </>
            )}
        </>
    )
}

export default withStyles(mitouStyles)(CaseTable);
