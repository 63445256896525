import {DialogContent, ListItem, withStyles} from '@material-ui/core';
import React, {useState} from 'react';
import gql from 'graphql-tag/lib/graphql-tag.umd';
import {useQuery} from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import {Loading, Title} from '@mitou/react-library';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

const GET_NEWS = gql`
    {
        getNews {
            id
            title
            description
            date
        }
    }
`;

function newsStyles(theme) {
    return {
        flexGrow: {
            flexGrow: 1,
        },
        newsContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        newsHeadline: {
            marginBottom: theme.spacing(1),
            fontSize: 16,
        },
        newsDescription: {
            overflow: 'auto',
            maxHeight: 230,
        },
        newsNavigation: {
            display: 'flex',
            justifyContent: 'center',
        },
        newsDateStyle: {
            padding: 5,
            color: '#264245',
            fontWeight: 'bold',
        },
    };
}

function News(props) {
    const {classes} = props;
    const {loading, data} = useQuery(GET_NEWS);
    const [newsIndex, setNewsIndex] = useState(0);
    const newsEntries = data && data.getNews.length > 0 ? data.getNews : null;
    const news = newsEntries !== null ? newsEntries[newsIndex] : null;
    const [dialogOpen, setOpen] = useState(false);
    const handleClickOpen = (index) => {
        setOpen(true);
        setNewsIndex(index);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Title>News</Title>
            {loading && <Loading/>}
            {!loading && (
                <div className={classes.newsContainer}>
                    {news && (
                            <div className={classes.flexGrow}>
                                <div className={classes.newsHeadline}>
                                    <List component="div">
                                        {newsEntries.map((news, index) => (
                                                <>
                                                    <ListItem className={classes.rowNews}
                                                              button
                                                              onClick={() => handleClickOpen(index)}
                                                    >
                                                        <Grid container className={classes.newsDateStyle}>
                                                            <Grid item xs={10}>
                                                                {news.title}
                                                            </Grid>
                                                            <Grid item xs={2} className={classes.datePosition}>
                                                                {moment(news.date).format('DD.MM.')}
                                                            </Grid>
                                                        </Grid>

                                                    </ListItem>
                                                    <Divider/>
                                                </>
                                        ))}
                                    </List>
                                </div>
                                <Dialog open={dialogOpen} onClose={handleClose} scroll={'body'}>
                                    <DialogTitle>{news.title}</DialogTitle>
                                    <DialogContent>
                                        <div dangerouslySetInnerHTML={{__html: news.description}}/>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary" autoFocus>
                                            Schließen
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                    )}
                    {!news && <div className={classes.flexGrow}>Keine News vorhanden.</div>}
                </div>
            )}
        </React.Fragment>
    );
}

export default withStyles(newsStyles)(News);

