import 'cross-fetch/polyfill';
import {config} from '../Constants'
import {ApolloLink} from 'apollo-link';
import {createHttpLink} from "apollo-link-http";
import {setContext} from "apollo-link-context";
import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloClient} from "apollo-client";
import {TokenRefreshLink} from "apollo-link-token-refresh";

const httpLink = createHttpLink({
    uri: config.GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('userToken');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
        }
    }
});

const refreshLink = new TokenRefreshLink({
    accessTokenField: 'token',
    isTokenValidOrUndefined: () => !!localStorage.getItem('userToken'),
    fetchAccessToken: () => {
        return fetch(config.API_URL + '/token/refresh', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                'refresh_token': localStorage.getItem('refreshToken')
            }
        });
    },
    handleFetch: accessToken => {
        localStorage.setItem('userToken', accessToken);
    },
    handleError: err => {
        // full control over handling token fetch Error
        console.warn('Your refresh token is invalid. Try to relogin');
        console.error(err);

        // your custom action here
        window.location.replace('/login');
    }
});

export const graphClient = new ApolloClient({
    link: ApolloLink.from([
        refreshLink,
        authLink,
        httpLink
    ]),
    cache: new InMemoryCache()
});
