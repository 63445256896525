import PortalClient from '../Component/PortalClient';

function getInvoices() {
    return PortalClient.get(`/invoices/get`)
        .then(({ data }) => Promise.resolve(data));
}

function getPdfInvoice(documentId) {
    return PortalClient.get(`/invoices/pdf/${documentId}`)
        .then(({data}) => Promise.resolve(data));
}

export const accountingService = {
    getInvoices,
    getPdfInvoice,
};

