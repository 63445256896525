import {PrivateRoute} from "../Component/PrivateRoute";
import Dashboard from "./Dashboard";
import Product from "./Product";
import OutOfStock from "./OutOfStock";
import CustomerShipmentPage from "./CustomerShipmentPage";
import CustomerInvoicesPage from "./CustomerInvoicesPage";
import RefundDocumentPage from "./RefundDocumentPage";
import RevenueCommissionDocumentPage from "./RevenueCommissionDocumentPage";
import SettingsPage from "./SettingsPage";
import React, {useEffect} from "react";
import {getUserAccounts} from "../Service/UserService";
import NavBar from "../Component/NavBar";
import {createStyles, withStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {changeAccountMarketplace} from "../Actions/account";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import {Loading} from "@mitou/react-library";
import {Redirect} from "react-router-dom";
import ArticleListPage from "./ArticleListPage";

const componentStyles = theme => createStyles({
    dashboardContent: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    dashboardContainer: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4),
    },
    flexContainer: {
        display: 'flex',
    },
});

function PrivatePage({classes, isLoggedIn}) {
    const accountLoaded = useSelector(state => state.account.loaded);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }
        if (accountLoaded === false) {
            getUserAccounts().then(({selectedAccount, selectedMarketplace, accounts}) => {
                dispatch(changeAccountMarketplace(selectedAccount ?? null, selectedMarketplace ?? null, accounts));
            })
        }
    }, [isLoggedIn, accountLoaded, dispatch]);

    if (!isLoggedIn) {
        return (
            <Redirect to='/login'/>
        );
    }

    return (
        <div className={classes.flexContainer}>
            <NavBar/>
            <main className={classes.dashboardContent}>
                {!accountLoaded && (
                    <Container maxWidth={false} className={classes.dashboardContainer}>
                        <Paper>
                            <Loading/>
                        </Paper>
                    </Container>
                )}
                {accountLoaded && (
                    <>
                        <PrivateRoute exact path='/' component={Dashboard}/>
                        <PrivateRoute exact path='/article' component={ArticleListPage}/>
                        <PrivateRoute exact path='/product-checker' component={Product}/>
                        <PrivateRoute exact path='/out-of-stock' component={OutOfStock}/>
                        <PrivateRoute exact path='/fulfillment' component={CustomerShipmentPage}/>
                        <PrivateRoute exact path='/invoices' component={CustomerInvoicesPage}/>
                        <PrivateRoute exact path='/fba-regulator/:documentId' component={RefundDocumentPage}/>
                        <PrivateRoute exact path='/commission/:documentId' component={RevenueCommissionDocumentPage}/>
                        <PrivateRoute exact path='/settings' component={SettingsPage}/>
                    </>
                )}
            </main>
        </div>
    );
}

export default withStyles(componentStyles)(PrivatePage);
