import {createMuiTheme} from "@material-ui/core";

export const mitouTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#3a6168',
            dark: '#274245'
        },
        secondary: {
            main: '#cc3d11'
        },
        text: {
            primary: '#264245',
            secondary: '#d9d9d9'
        },
        error: {
            main: '#cc3d11',
        },
        background: {
            default: '#d9d9d9',
        },
    },
    typography: {
        fontSize: 12,
        h2: {
            fontSize: '1.6rem',
            lineHeight: 1.2,
            paddingTop: 10,
        },
        h3: {
            fontSize: '1.2rem',
            lineHeight: 1.1,
            paddingTop: 10,
        },
    },
    overrides: {
        MuiTableCell: {
            sizeSmall: {
                paddingLeft: 10,
                paddingRight: 18
            },
            head: {
                color: "#264245",
                fontWeight: "bold"
            },
        },
        MuiFormLabel: {
            root: {
                color: "#3a6168",
            },
        },
        MuiListSubheader: {
            root: {
                color: "#3a6168",
                fontSize: '1rem',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: 10
            },
            multiline: {
                padding: 10
            },
        },
        MuiButton: {
            root: {
                marginRight: 5,
            }
        },
    }
});


export const mitouInvertedTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#fff'
        },
        text: {
            primary: '#fff',
            secondary: '#d9d9d9'
        }
    },
    typography: {
        fontSize: 12,
    },
    overrides: {
        MuiTableCell: {
            sizeSmall: {
                paddingLeft: 10,
                paddingRight: 18
            }
        }
    }
});
