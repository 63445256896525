import React from "react";
import MaterialTextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

function TextField(props) {
    const {id, label, value, onChange} = props;

    return (
        <MaterialTextField
            id={id}
            label={label}
            value={value}
            required={false}
            onChange={(event) => onChange(event.target.value)}
            margin="normal"
            InputLabelProps={{
                shrink: true
            }}
        />
    )
}

TextField.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default TextField;
