import PortalClient from '../Component/PortalClient'
import moment from "moment";

export const dashboardService = {
    accountHealth,
    advertising,
    sales,
    tasks,
    waitForApprovalTasks,
    approvedTask
};

export function getDashboardData(accountId, marketplace) {
    const params = {
        account: accountId,
        marketplace: marketplace,
    };

    return PortalClient.get('/dashboard/data', {params: params}).then(response => {

        return Promise.resolve(response.data);
    });
}

function accountHealth() {
    return PortalClient.get('/accountHealth/get').then(response => {

        return Promise.resolve(response.data);
    });
}

function advertising() {
    return PortalClient.get('/advertising/get').then(response => {

        return Promise.resolve(response.data);
    });
}

function sales() {
    return PortalClient.get('/sales/get').then(response => {

        return Promise.resolve(response.data);
    });
}


function tasks(month) {
    const params = {
        from: moment(month).utc().startOf('month').toISOString(),
        to: moment(month).utc().endOf('month').toISOString(),
    };

    return PortalClient.get('/task/get', {params: params}).then(response => {

        return Promise.resolve(response.data);
    });
}

function waitForApprovalTasks() {
    return PortalClient.get('/task/waitForApproval').then(response => {

        return Promise.resolve(response.data);
    });
}

function approvedTask(task, decision) {
    return PortalClient.get('/task/approved/' + task.id + '/' + decision).then(response => {

        return Promise.resolve(response.data);
    });
}
