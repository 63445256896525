import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

export interface SelectOption {
    value?: string | number
    label: string | React.ReactNode
}

interface SelectProps {
    id?: string
    label?: React.ReactNode
    value?: string
    values: SelectOption[]
    className?: string
    onChange(value: string): void
}

function Select({values, onChange, ...props}: SelectProps) {

    return (
        <TextField
            select
            {...props}
            required={false}
            onChange={(event) => onChange(event.target.value)}
            margin="normal"
            InputLabelProps={{
                shrink: true
            }}
        >
            {values.map((entry) => (
                <MenuItem key={String(entry.value)}
                          value={entry.value}>{entry.label}</MenuItem>
            ))}
        </TextField>
    )
}

export default Select;
