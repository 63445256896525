import React, {useEffect, useState} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {createNotificationSubscription, getUserSubscription,} from "../../Helpers/PushNotifications";
import gql from "graphql-tag/lib/graphql-tag.umd";
import {useMutation} from "@apollo/react-hooks";

const CREATE_SUBSCRIPTION = gql`
mutation createSubscription(
    $endpoint: String!
    $auth: String
    $publicKey: String
) {
    createSubscription(
        endpoint: $endpoint
        auth: $auth
        publicKey: $publicKey
    )
}
`;

const DELETE_SUBSCRIPTION = gql`
mutation deleteSubscription(
    $endpoint: String!
) {
    deleteSubscription(
        endpoint: $endpoint
    )
}
`;

function PushSubscriptionButton() {
    const [userSubscription, setUserSubscription] = useState(null);
    const [deleteSubscription] = useMutation(DELETE_SUBSCRIPTION);
    const [createSubscription] = useMutation(CREATE_SUBSCRIPTION);

    useEffect(() => {
        const getExixtingSubscription = async () => {
            const existingSubscription = await getUserSubscription();

            setUserSubscription(existingSubscription);
            if (existingSubscription !== null) {
                await createSubscription({variables: {
                    endpoint: existingSubscription.endpoint,
                    auth: existingSubscription.keys && existingSubscription.keys.auth ? existingSubscription.keys.auth : null,
                    publicKey: existingSubscription.keys && existingSubscription.keys.p256dh ? existingSubscription.keys.p256dh : null,
                }});
            }
        };
        getExixtingSubscription();
    }, [createSubscription]);

    const handleChange = () => {
        if (userSubscription !== null) {
            userSubscription.unsubscribe().then(async () => {
                await deleteSubscription({variables: {endpoint: userSubscription.endpoint}});
                setUserSubscription(null);
            })
        } else {
            createNotificationSubscription()
                .then(async function(subscription) {
                    setUserSubscription(subscription);
                    await createSubscription({variables: {
                            endpoint: subscription.endpoint,
                            auth: subscription.keys && subscription.keys.auth ? subscription.keys.auth : null,
                            publicKey: subscription.keys && subscription.keys.p256dh ? subscription.keys.p256dh : null,
                        }});
                })
                .catch(err => {
                    console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
                });
        }
    };

    return (
        <FormControlLabel
            control={
                <Switch checked={userSubscription !== null} onChange={handleChange} value="checkedA" />
            }
            label="Benachrichtigungen"
        />
    )
}

export default PushSubscriptionButton;
