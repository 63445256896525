const pushServerPublicKey = "BO2hCjb2euPY92RU9Ob6MlN8UYRSzYjESSEtWpxI9-GEaOmfLT3bwterKmBOsHEs1wko0-ttHqkQOAzQwYlR-6M";

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 *
 */
function registerServiceWorker() {
    return navigator.serviceWorker.register("/sw.js");
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
    //wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready;
    // subscribe and return the subscription
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: pushServerPublicKey
    });
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
    //wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready
        .then(function(serviceWorker) {
            return serviceWorker.pushManager.getSubscription();
        })
        .then(function(pushSubscription) {
            return pushSubscription;
        });
}

export {
    isPushNotificationSupported,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription
};
