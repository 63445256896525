import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import {Tab, withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import Tabs from "@material-ui/core/Tabs";
import LogisticLoginSettings from "../Component/Settings/LogisticLoginSettings";
import {TabPanel, TabPanelContainer, Title} from "@mitou/react-library";

interface SettingsPageClasses {
    dashboardPaper: string;
    dashboardFullHeight: string;
    flexContainer: string;
    dashboardContainer: string;
}

interface SettingsPageProps {
    classes: SettingsPageClasses;
}

function SettingsPage({classes}: SettingsPageProps) {
    const fullHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFullHeight);
    const [activeTab, setActiveTab] = useState<string>('logisticLogin');
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setActiveTab(newValue);
    };

    return (
        <Container maxWidth={false} className={classes.dashboardContainer}>
            <Paper className={fullHeightPaper}>
                <Title>Einstellungen</Title>
                <Tabs
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                >
                    <Tab value={"logisticLogin"} label="Logistik-Logins" />
                </Tabs>
                <TabPanelContainer>
                    <TabPanel selectedValue={activeTab} value="logisticLogin">
                        <LogisticLoginSettings/>
                    </TabPanel>
                </TabPanelContainer>
            </Paper>
        </Container>
    );
}

export default withStyles(mitouStyles)(SettingsPage);
