import {connect} from "react-redux";
import {Typography, withStyles} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import Button from "@material-ui/core/Button";
import gql from "graphql-tag/lib/graphql-tag.umd";
import {useQuery} from "@apollo/react-hooks";
import {truncateName} from "../../Helpers/ItemHelper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {config} from '../../Constants'
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import {formatMoney, PRODUCT_CHECKER_TYPE_LABELS} from "@mitou/react-library/build";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ProductCheckerChart} from "./ProductCheckerChart";

const GET_LISTING = gql`
query getProductCheckerListing(
        $listingId: Int!
    ) {
    getProductCheckerListing(listingId: $listingId) {
        listing {
            product {
                description
            }
        }
        image {
            url
            image
        }
        changes {
            changedAt
            attribute
            before
            after
        }
        chartData {
            date
            order
        }
    }
}
`;

function dialogStyles(theme) {

    return {
        descContainer: {
            marginTop: theme.spacing(3),
            maxHeight: 300,
            overflow: 'scroll'
        },
        changesHeadline: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },
        mediumDialog: {
            minWidth: '50%',
        },
    }
}

function ProductCheckerDialog(props) {
    const {classes, listing, onClose} = props;
    const {data} = useQuery(GET_LISTING, {
        variables: {
            listingId: listing.id
        },
    });
    let image = null;
    let detailListing = null;
    let changes = null;
    let chartData = null;
    if (data) {
        detailListing = data.getProductCheckerListing.listing;
        changes = data.getProductCheckerListing.changes;
        chartData = data.getProductCheckerListing.chartData;
        if (data.getProductCheckerListing.image.image) {
            image = config.IMAGE_HOST + data.getProductCheckerListing.image.image;
        } else {
            image = data.getProductCheckerListing.image.url;
        }
    }

    return (
        <Dialog open={true} onClose={onClose} classes={{paper: classes.mediumDialog}}>
            <DialogTitle>
                {truncateName(listing.name, 100)}
                <IconButton className={classes.closeButton} onClick={onClose}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {detailListing &&
                    <Container maxWidth={false}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={4}>
                                {image &&
                                <img alt="" src={image} style={{maxWidth: 150}}/>
                                }
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <div>
                                    <strong>ASIN:</strong> {listing.sku}<br/>
                                    <strong>SKU:</strong> {listing.product.asin}<br/>
                                </div>
                                <div className={classes.descContainer} dangerouslySetInnerHTML={{__html: detailListing.product.description}}>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"h4"} className={classes.changesHeadline}>Bestellungen der letzten 30 Tage</Typography>
                                <ProductCheckerChart data={chartData} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"h4"} className={classes.changesHeadline}>Änderungen der letzten 7 Tage</Typography>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Datum</TableCell>
                                            <TableCell>Eigenschaft</TableCell>
                                            <TableCell>vorher</TableCell>
                                            <TableCell>nachher</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {changes.map((change, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{moment(change.changedAt).format('DD.MM.YYYY')}</TableCell>
                                                <TableCell>{PRODUCT_CHECKER_TYPE_LABELS[change.attribute]}</TableCell>
                                                <TableCell>{change.before ? showDiff(change.attribute, change.before) : '-'}</TableCell>
                                                <TableCell>{change.after ? showDiff(change.attribute, change.after) : '-'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Container>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    Schliessen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function showDiff(type, data) {
    data = JSON.parse(data);
    if (data === null) {
        return '-';
    }
    if (type === 'SmallImage') {
        return (<img alt="" src={data.URL} />);
    } else if (['ItemDimensions', 'PackageDimensions'].indexOf(type) >= 0) {
        return (
            <React.Fragment>
                {data.Length &&
                <div><strong>Länge:</strong> {convertValueToCm(data.Length.value, data.Length.Units)}</div>
                }
                {data.Width &&
                <div><strong>Breite:</strong> {convertValueToCm(data.Width.value, data.Width.Units)}</div>
                }
                {data.Height &&
                <div><strong>Höhe:</strong> {convertValueToCm(data.Height.value, data.Height.Units)}</div>
                }
                {data.Weight &&
                <div><strong>Gewicht:</strong> {convertValueToKg(data.Weight.value, data.Weight.Units)}</div>
                }
            </React.Fragment>
        )
    } else if (type === 'ListPrice') {
        return formatMoney(data.Amount, data.CurrencyCode);
    } else {
        return data;
    }
}

function convertValueToCm(value, unit) {
    if (unit === 'inches') {
        unit = 'cm';
        value /= 0.3937;
    }
    return Math.round(value) + ' ' + unit;
}

function convertValueToKg(value, unit) {
    if (unit === 'pounds') {
        unit = 'kg';
        value /= 2.2046;
        if (value < 1) {
            unit = 'g';
            value *= 1000;
        }
    }
    return Math.round(value) + ' ' + unit;
}

function mapStateToProps(state) {
    return {...state.account};
}

export default connect(mapStateToProps)(withStyles(dialogStyles)(ProductCheckerDialog));
