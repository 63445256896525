import PortalClient from '../Component/PortalClient'

const baseUrl = '/customer-shipment/';

export function getCustomerShipments(shipmentId, states, priority, taskType) {
    let params;
    if (shipmentId) {
        params = {shipmentId};
    } else {
        params = {states, priority, taskType};
    }

    return PortalClient.get(baseUrl, {params})
        .then((res) => Promise.resolve(res.data));
}

export function createCustomerShipment(shipment) {
    return PortalClient.post(baseUrl, shipment)
        .then((res) => Promise.resolve(res.data));
}

export function updateCustomerShipment(id, shipment) {
    return PortalClient.put(baseUrl + id, shipment)
        .then((res) => Promise.resolve(res.data));
}

export function deleteCustomerShipment(id) {
    return PortalClient.delete(baseUrl + id)
        .then((res) => Promise.resolve(res.data));
}
