import {WithStyles, withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import {
    AmazonListing,
    AmazonListingDetails,
    AmazonListingDetailsFilter,
    FulfillmentValue,
    List,
    ListingComponent,
    ListingTableFilterOptions,
    ListingTableFilterStatus,
    ListingTableQuery,
    Loading,
    PlatformType
} from "@mitou/react-library/build";
import {useSelector} from "react-redux";
import {RootState} from "../Helpers/Store";
import {useApolloClient, useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import OutOfStockDialog from "../Component/OutOfStock/OutOfStockDialog";

const GET_AMAZON_LISTINGS_QUERY = gql`
    query getAmazonListings(
        $accountId: Int!
        $marketplace: Marketplace!
        $search: String
        $status: String
        $fullfillment: String
        $page: Int!
        $filter: AmazonListingFilter!
        $customRangeFrom: DateTime
        $customRangeTo: DateTime
        $reviewAverage: Float
    ) {
        getAmazonListings(
            accountId: $accountId
            marketplace: $marketplace
            search: $search
            status: $status
            fullfillment: $fullfillment
            page: $page
            filter: $filter
            customRangeFrom: $customRangeFrom
            customRangeTo: $customRangeTo
            reviewAverage: $reviewAverage
        ) {
            listings {
                id
                name
                asin
                sku
                currentPrice
                currentQuantity
                fba
                active
                product {
                    updatedAt
                    lastChangedAttributes
                    image {
                        url
                        image
                    }
                }
                orderStatistics {
                    salesToday
                    quantityToday
                    salesYesterday
                    quantityYesterday
                    salesCurrentWeek
                    quantityCurrentWeek
                    salesLastWeek
                    quantityLastWeek
                    salesCurrentMonth
                    quantityCurrentMonth
                    salesLastMonth
                    quantityLastMonth
                    salesAllTime
                    quantityAllTime
                    salesCustom
                    quantityCustom
                    estimatedQuantityNextWeek
                    estimatedQuantityNextMonth
                    outOfStockInDays
                    outOfStockInDaysWithLead
                }
                outOfStock {
                    hidden
                    processed
                    hiddenAt
                    processedAt
                }
            }
            total
            page
        }
    }
`;

interface GetAmazonListingsVariables {
    accountId: number
    marketplace: string
    search?: string
    page: number
    status: ListingTableFilterStatus
    fullfillment: FulfillmentValue
    filter: ListingTableFilterOptions
    customRangeFrom?: string
    customRangeTo?: string
    reviewAverage?: number
}

interface GetAmazonListingsData {
    getAmazonListings: {
        listings: AmazonListing[]
        total: number
        page: number
    }
}

interface ArticleListPageProps extends WithStyles<typeof mitouStyles> {
}

const AMAZON_LISTINGS_DETAILS_QUERY = gql`
    query getAmazonListingDetails(
        $listingId: Int!
        $range: String!
        $customRangeFrom: DateTime
        $customRangeTo: DateTime
        $reviewFilter: Int
        $productCheckerFilter: AmazonListingFilter
    ) {
        getAmazonListingDetails(
            id: $listingId
            range: $range
            customRangeFrom: $customRangeFrom
            customRangeTo: $customRangeTo
            reviewFilter: $reviewFilter
            productCheckerFilter: $productCheckerFilter
        ) {
            advertising {
                metaCampaign {
                    name
                    type
                }
                profile {
                    label
                }
                adGroup {
                    id
                    name
                }
                reportByRange {
                    impressions
                    clicks
                    cost
                    conversions
                    sales
                    ctr
                    cpc
                    conversionRate
                    acos
                    racos
                }
            }
            listing {
                name
                asin
                sku
                fba
                active
                currentPrice
                currentQuantity
                marketplace
                product {
                    reviewAverage
                    reviewCount
                    image {
                        url
                        image
                    }
                }
                orderStatistics {
                    salesToday
                    quantityToday
                    salesYesterday
                    quantityYesterday
                    salesCurrentWeek
                    quantityCurrentWeek
                    salesLastWeek
                    quantityLastWeek
                    salesCurrentMonth
                    quantityCurrentMonth
                    salesLastMonth
                    quantityLastMonth
                    salesAllTime
                    quantityAllTime
                    salesCustom
                    quantityCustom
                    estimatedQuantityNextWeek
                    estimatedQuantityNextMonth
                    outOfStockInDays
                    outOfStockInDaysWithLead
                }
            }
            reviews {
                id
                date
                rating
                title
                description
                successful
                processed
            }
            siblings {
                id
                name
                asin
                sku
            }
            defects {
                fieldName
                alertType
                currentValue
                description
                lastUpdatedAt
            }
            changes {
                changedAt
                attribute
                before
                after
            }
            ordersByDay {
                date
                sales
                quantity
            }
            reimbursements {
                date
                caseId
                reason
                itemCondition
                reimbursementAmountPerUnit
                reimbursementAmountTotal
                quantityReimbursedInCash
                quantityReimbursedInInventory
                quantityReimbursedTotal
            }
        }
    }
`;

function ArticleListPage({classes}: ArticleListPageProps) {
    const fullHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFullHeight);
    const marketplace = useSelector<RootState, string | null>(({account}) => account.marketplace);
    const accountId = useSelector<RootState, number | null>(({account}) => account.accountId);
    const [data, setData] = useState<List<AmazonListing>>();
    const [loading, setLoading] = useState<boolean>(true);
    const [openSettingDialog, setOpenSettingDialog] = useState<boolean>(false);
    const getAmazonListingsOnCompleted = ({getAmazonListings}: GetAmazonListingsData) => {
        const list: List<AmazonListing> = {
            total: getAmazonListings.total,
            page: getAmazonListings.page,
            maxPage: Math.ceil(getAmazonListings.total / 50),
            results: getAmazonListings.listings,
        };
        setData(list);
        setLoading(false);
    };
    const [getAmazonListings, {refetch}] = useLazyQuery<GetAmazonListingsData, GetAmazonListingsVariables>(
        GET_AMAZON_LISTINGS_QUERY,
        {
            onCompleted: getAmazonListingsOnCompleted,
        }
    );
    const [variables, setVariables] = useState<GetAmazonListingsVariables>();
    const refetchOutOfStock = () => {
        setLoading(true);
        setData(undefined);
        refetch().then(({data}) => {
            getAmazonListingsOnCompleted(data);
        });
    }
    const client = useApolloClient();

    if (marketplace === null || accountId === null) {
        return (<Loading/>);
    }

    const loadData = (query: ListingTableQuery) => {
        const newVariables: GetAmazonListingsVariables = {
            accountId: accountId,
            marketplace: marketplace,
            page: query.page,
            search: query.search ? query.search : undefined,
            status: query.status,
            fullfillment: query.fulfillment,
            filter: query.filter,
            customRangeFrom: query.customRangeFrom ? query.customRangeFrom.toISOString() : undefined,
            customRangeTo: query.customRangeTo ? query.customRangeTo.toISOString() : undefined,
            reviewAverage: query.reviewAverage
        };
        if (variables && JSON.stringify(variables) === JSON.stringify(newVariables)) {
            return;
        }

        setVariables(newVariables);
        setLoading(true);
        setData(undefined);

        getAmazonListings({
            variables: newVariables
        });
    }
    const getAmazonListingDetails = (filter: AmazonListingDetailsFilter): Promise<AmazonListingDetails> => {
        return new Promise<AmazonListingDetails>(resolve => {
            client.query<{ getAmazonListingDetails: AmazonListingDetails }, AmazonListingDetailsFilter>({
                query: AMAZON_LISTINGS_DETAILS_QUERY,
                variables: filter,
            }).then(({data}) => resolve(data.getAmazonListingDetails));
        })
    }

    return (
        <Container maxWidth={false} className={classes.dashboardContainer}>
            <Paper className={fullHeightPaper}>
                <ListingComponent loadData={loadData} loading={loading} data={data}
                                  platform={PlatformType.PORTAL} marketplace={marketplace}
                                  getAmazonListingDetails={getAmazonListingDetails}
                                  setOpenSettingDialog={setOpenSettingDialog}/>
                {openSettingDialog && (
                    <OutOfStockDialog open={openSettingDialog} onClose={() => {
                        refetchOutOfStock();
                        setOpenSettingDialog(false);
                    }}/>
                )}
            </Paper>
        </Container>
    )
}

export default withStyles(mitouStyles)(ArticleListPage);
