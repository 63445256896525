import PortalClient from '../Component/PortalClient'

export const todoService = {
    getTodos,
    createTodo,
    updateTodo,
    deleteTodo
};

function getTodos(filter) {
    return PortalClient.get('/todo', {params: {filter: filter}}).then(response => {

        return Promise.resolve(response.data);
    });
}

function createTodo(data) {
    return PortalClient.post('/todo/create', data).then(response => {

        return Promise.resolve(response.data);
    });
}

function updateTodo(id, data) {
    return PortalClient.put('/todo/' + id + '/update', data).then(response => {

        return Promise.resolve(response.data);
    });
}

function deleteTodo(id) {
    return PortalClient.delete('/todo/' + id + '/delete').then(response => {

        return Promise.resolve(response.data);
    });
}
