import React, {useEffect, useState} from 'react';
import {ExpansionPanelDetails, withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {CommissionService} from "../Service/CommissionService";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {formatMoney} from "@mitou/react-library/build";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Divider from "@material-ui/core/Divider";

export default withStyles(mitouStyles)(function RevenueCommissionDocumentPage(props) {
    const { classes } = props;
    const [dataLoaded, setDataLoaded] = useState(false);
    const [revCommissionData, setRevCommissionData] = useState([]);
    const [revenueMarketplaces, setRevenueMarketplaces] = useState(<></>);
    const [returnsMarketplaces, setReturnsMarketplaces] = useState(<></>);

    useEffect(() => {
        const prepRevenue = (revenues) => {
            setRevenueMarketplaces(revenues.map((revenueData, index) => {
                    return (
                        <ListItem key={index} className={`${classes.revenueListEntry}`}>
                            <Grid container>
                                <Grid item xs={6}>
                                    { revenueData.marketplace }
                                </Grid>
                                <Grid item xs={6} className={`${classes.textRight}`}>
                                    { formatMoney(revenueData.net_amount / 100, 'EUR') }
                                </Grid>
                            </Grid>
                        </ListItem>
                    )
                }
            ));
        };

        const prepReturns = (returns) => {
            const marketplaces = returns.map((returnData) => returnData.marketplace);
            const distinctMarketplaces = new Set(marketplaces);
            let returnsByMarketplace = {};

            distinctMarketplaces.forEach((marketplace) => {
                returnsByMarketplace[marketplace] = {'sum': 0, returnData: []};
                returns.forEach((returnData) => {
                    if (returnData.marketplace === marketplace) {
                        returnsByMarketplace[marketplace]['sum'] += returnData.net_amount;
                        returnsByMarketplace[marketplace]['returnData'].push(returnData);
                    }
                });
            });

            setReturnsMarketplaces(Object.keys(returnsByMarketplace).map((marketplace, index) => {
                let monthData = [];
                returnsByMarketplace[marketplace]['returnData'].forEach((returnData, index) => {
                    monthData.push(
                        <ListItem key={index} className={`${classes.revenueListEntry}`}>
                            <Grid container>
                                <Grid item xs={6}>
                                    { moment(returnData.date).format('MM.YYYY') }
                                </Grid>
                                <Grid item xs={6} className={`${classes.textRight} ${classes.totalEntryReturn}`}>
                                    { formatMoney(returnData.net_amount / 100, 'EUR') }
                                </Grid>
                            </Grid>
                        </ListItem>
                    )
                });

                return (
                    <ExpansionPanel key={index} className={`${classes.returnExpansionPanel}`}>
                        <ExpansionPanelSummary
                            expandIcon={
                                <FontAwesomeIcon fixedWidth={true} icon={["far", "arrow-circle-down"]} />
                            }
                            className={`${classes.noPaddingLeftRight}`}
                        >
                            <Grid item xs={6}>
                                { marketplace }
                            </Grid>
                            <Grid item xs={6} className={`${classes.textRight} ${classes.totalEntryReturn}`}>
                                { formatMoney(returnsByMarketplace[marketplace].sum / 100, 'EUR') }
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <List className={`${classes.returnListExpandable}`}>
                                <ListItem key={index} className={`${classes.revenueListEntry}`}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Monat der Rücksendung
                                        </Grid>
                                        <Grid item xs={6} className={`${classes.textRight} ${classes.totalEntryReturn}`}>
                                            Erstatteter Betrag im Abrechnungszeitraum
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                { monthData }
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            }));
        };

        const { documentId } = props.match.params;
        CommissionService.getCommissionData(documentId).then((response) => {
            setRevCommissionData(response);
            setDataLoaded(true);
            prepRevenue(response.marketplace_revenues);
            prepReturns(response.marketplace_returns);
        });
    }, [props.match.params, classes]);

    return (
        <Container maxWidth={false} className={classes.dashboardContainer}>
            {!dataLoaded && (
                <Box className={classes.progressContainer}>
                    <CircularProgress className={classes.progress}/>
                </Box>
            )}
            {dataLoaded && (
                <Grid container spacing={1}>
                    <Grid item xs={false} sm={2}/>
                    <Grid item xs={12} sm={8}>
                        <Paper className={classes.revenuePaper}>
                            <h3>
                                Provisionsabrechnung vom
                                {' '}
                                { moment(revCommissionData.start_date).format('DD. MMMM')}
                                {' '}
                                bis
                                {' '}
                                { moment(revCommissionData.end_date).format('DD. MMMM Y')}
                                {' '}
                                (Rechnungs-Nr.
                                {' '}
                                { revCommissionData.easybill_position.document.number }
                                )
                            </h3>
                        </Paper>
                    </Grid>
                    <Grid item xs={false} sm={2}/>
                    <Grid item xs={false} sm={2}/>
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Paper className={classes.revenuePaper}>
                                    <h3>Umsätze nach Marktplatz (Netto)</h3>
                                    <List className={`${classes.noPaddingTopBottom}`}>
                                        { revenueMarketplaces }
                                    </List>
                                    <Divider/>
                                    <Grid container className={classes.totalEntry}>
                                        <Grid item xs={6}>
                                            Gesamtumsatz (netto)
                                        </Grid>
                                        <Grid item xs={6} className={`${classes.textRight}`}>
                                            { formatMoney(revCommissionData.total_net_revenue / 100, 'EUR') }
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper className={`${classes.revenuePaper}`}>
                                    <h3>Erstattungen nach Marktplatz (Netto)</h3>
                                    { returnsMarketplaces }
                                    <Grid container className={`${classes.totalEntry}`}>
                                        <Grid item xs={6}>
                                            Gesamte Erstattungen (netto)
                                        </Grid>
                                        <Grid item xs={6} className={`${classes.textRight} ${classes.totalReturnNumber}`}>
                                            { formatMoney(revCommissionData.total_net_returns / 100, 'EUR') }
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={false} sm={2}/>
                    <Grid item xs={false} sm={2}/>
                    <Grid item xs={12} sm={8}>
                        <Paper className={`${classes.revenuePaper} ${classes.totalRevenuePaper}`}>
                            <Grid container>
                                <Grid item xs={12} sm={4} className={classes.textCenter}>
                                    Bereinigte Netto-Umsätze:
                                    {' '}
                                    { formatMoney((revCommissionData.total_net_revenue + revCommissionData.total_net_returns) / 100, 'EUR') }
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.textCenter}>
                                    Provision in Prozent:
                                    {' '}
                                    { revCommissionData.commission_percent } %
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.textCenter}>
                                    Provision:
                                    {' '}
                                    { formatMoney(revCommissionData.commission_value / 100, 'EUR') }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Container>
    )
});
