import PortalClient from '../Component/PortalClient'

export default {
    login,
    logout,
    isLoggedIn,
    getContactPerson,
};

function login(username, password) {
    return PortalClient.post('/login_check', {'username': username, 'password': password})
        .then(response => {
            localStorage.setItem('userToken', response.data.token);
            localStorage.setItem('refreshToken', response.data.refresh_token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('customer', JSON.stringify(response.data.customer));
            localStorage.setItem('accounts', JSON.stringify(response.data.accounts));
            if (response.data.contactPerson) {
                localStorage.setItem('contactPerson', JSON.stringify(response.data.contactPerson));
            } else {
                localStorage.removeItem('contactPerson');
            }

            return Promise.resolve(response.data);
        });
}

function logout() {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userToken');
    localStorage.removeItem('user');
    localStorage.removeItem('customer');
    localStorage.removeItem('accounts');
    localStorage.removeItem('contactPerson');
}

function isLoggedIn() {
    return localStorage.getItem('userToken') !== null;
}

function getContactPerson() {
    if (localStorage.getItem('contactPerson')) {
        return JSON.parse(localStorage.getItem('contactPerson'));
    }
    return null;
}

export function getUserAccounts() {
    return PortalClient.get('/user/accounts').then(response => {
        return Promise.resolve(response.data);
    });
}
