import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import React from "react";
import ProductChecker from "../Component/Product/ProductChecker";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Container from "@material-ui/core/Container";

function ProductCheckerPage({classes}) {
    const fullHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFullHeight);

    return (
        <Container maxWidth={false} className={classes.dashboardContainer}>
            <Paper className={fullHeightPaper}>
                <ProductChecker/>
            </Paper>
        </Container>
    );
}

export default withStyles(mitouStyles)(ProductCheckerPage);
