import React from 'react';
import {Box, Link, makeStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import {UserAvatar} from "../UserAvatar";
import UserService from "../../Service/UserService";
import {config} from '../../Constants';
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {ActivityStatus} from "./ActivityStatus";
import {Title} from "@mitou/react-library";

const useStyles = makeStyles(mitouStyles);

const ACTIVITY_STATUS = gql`
{
    getActivityStatus
}
`;

export function Contact() {
    const classes = useStyles();
    const contactPerson = UserService.getContactPerson();
    const {loading, data} = useQuery(ACTIVITY_STATUS, {pollInterval: 60000});

    return (
        <React.Fragment>
            <Title>Ihr Ansprechpartner</Title>
            <Box className={classes.contactPersonContainer}>
                <UserAvatar user={contactPerson} className={classes.contactPersonAvatar} />
                <Box>
                    <strong>{contactPerson.firstName} {contactPerson.lastName}</strong><br/>
                    {!loading &&
                    <ActivityStatus status={data && data.getActivityStatus} />
                    }
                    <br/>
                    <small>
                        <strong>E-Mail:</strong> <Link component={"a"} color={"inherit"} href={"mailto:" + contactPerson.email}>{contactPerson.email}</Link><br/>
                        <strong>Telefon:</strong> <Link component={"a"} color={"inherit"} href={"tel:" + config.DEFAULT_PHONE}>{config.DEFAULT_PHONE}</Link><br/>
                    </small>
                </Box>
            </Box>
        </React.Fragment>
    );
}
