import {combineReducers, createStore} from 'redux';
import {task} from "../Reducer/task";
import {account} from "../Reducer/account";

const rootReducer = combineReducers({
    task,
    account
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer);
