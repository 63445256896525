import React from 'react';
import Chip from "@material-ui/core/Chip";

function getLabel(status) {
    switch (status) {
        case 'ACTIVE':
            return 'aktiv';

        case 'AWAY':
            return 'abwesend';

        case 'INACTIVE':
        default:
            return 'offline';
    }
}
function getLabelColor(status) {
    switch (status) {
        case 'ACTIVE':
            return '#00A636';

        case 'AWAY':
            return '#FFC341';

        case 'INACTIVE':
        default:
            return '#BE1E1E';
    }
}

export function ActivityStatus(props) {
    const {status} = props;

    return (
        <Chip style={{color: getLabelColor(status)}} label={getLabel(status)} size={"small"} variant={"outlined"} />
    );
}
