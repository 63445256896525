/* eslint-disable no-script-url */

import React, {useRef} from 'react';
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import momentPlugin from '@fullcalendar/moment';
import deLocale from '@fullcalendar/core/locales/de';
import moment from "moment";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {withStyles} from "@material-ui/core";
import {Title} from "@mitou/react-library";

function calendarStyles() {
    return {
        flexGrow: {
            flexGrow: 1,
        },
        titleContainer: {
            display: "flex",
        },
    };
}

function Calendar(props) {
    const {classes} = props;
    const calendarRef = useRef(null);
    const titleRef = useRef(null);

    return (
        <React.Fragment>
            <Title className={classes.titleContainer}>
                <div ref={titleRef} className={classes.flexGrow}>{moment().format('MMMM YYYY')}</div>
                <ButtonGroup size="small">
                    <Button onClick={() => calendarRef.current.getApi().prev()}><FontAwesomeIcon fixedWidth={true} icon={['far', 'chevron-left']}/></Button>
                    <Button onClick={() => calendarRef.current.getApi().next()}><FontAwesomeIcon fixedWidth={true} icon={['far', 'chevron-right']}/></Button>
                </ButtonGroup>
            </Title>
            <FullCalendar
                header={false}
                ref={calendarRef}
                locale={deLocale}
                defaultView="dayGridMonth"
                weekends={false}
                plugins={[dayGridPlugin, momentPlugin]}
                datesRender={(info) => titleRef.current.innerHTML = info.view.title}
            />
        </React.Fragment>
    );
}

export default withStyles(calendarStyles)(Calendar);
