import React, {useEffect, useState} from 'react';
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import moment from 'moment';
import {accountingService} from '../Service/AccountingService';
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import MaterialTable from "@material-table/core";

export default withStyles(mitouStyles)(function CustomerInvoicesPage({classes}) {

    const fullHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFullHeight, classes.invoiceTablePaper);
    const invoiceContainer = clsx(classes.dashboardContainer, classes.invoiceTableContainer);

    const [invoices, setInvoices] = useState([]);
    const [invoicesLoaded, setInvoicesLoaded] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);

    useEffect(() => {
        accountingService.getInvoices().then((response) => {
            setInvoicesLoaded(true);
            setInvoices(response);
        })
    }, []);

    const openDocumentPdf = (documentId) => {
        accountingService.getPdfInvoice(documentId).then(
            (response) => {
                const binary = atob(response);
                const buffer = new ArrayBuffer(binary.length);
                const view = new Uint8Array(buffer);
                for (let i = 0; i < binary.length; i++) {
                    view[i] = binary.charCodeAt(i);
                }
                const file = new Blob([view], { type: 'application/pdf' });
                setBackdropOpen(false);
                window.open(URL.createObjectURL(file));
            }
        );
    };

    return (
        <Container maxWidth={false} className={invoiceContainer}>
            <Paper className={fullHeightPaper}>
                <div className="text-center">
                {!invoicesLoaded && (
                    <Box className={classes.progressContainer}>
                        <CircularProgress className={classes.progress}/>
                    </Box>
                )}
                {invoicesLoaded && (
                    <>
                        <Backdrop className={classes.backdrop} open={backdropOpen}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <MaterialTable
                            title="Rechnungen"
                            actions={[
                                {
                                    icon: 'visibility',
                                    tooltip: 'PDF ansehen',
                                    onClick: (event, rowData) => {
                                        setBackdropOpen(true);
                                        openDocumentPdf(rowData.document_id);
                                    },
                                },
                            ]}
                            columns={[
                                {
                                    title: 'Dokumenten-Nummer',
                                    field: 'number',
                                },
                                {
                                    title: 'Rechnung vom',
                                    field: 'created_at',
                                    render: (rowData) => <>{ moment(rowData.created_at).format('DD.MM.YYYY') }</>,
                                },
                                {
                                    title: 'Betrag',
                                    field: 'amount',
                                    render: (rowData) => (
                                        <>
                                            { (rowData.amount / 100).toFixed(2) }
                                            €
                                        </>
                                    ),
                                },
                                {
                                    title: 'Betrag (netto)',
                                    field: 'amount_net',
                                    render: (rowData) => (
                                        <>
                                            { (rowData.amount_net / 100).toFixed(2) }
                                            €
                                        </>
                                    ),
                                },
                                {
                                    title: 'Weitere Informationen',
                                    render: (rowData) => {
                                        if (rowData.is_refund_invoice) {
                                            return <Link className={classes.link} to={`/fba-regulator/${rowData.id}`}>FBA-Regulator Rechnung</Link>
                                        } else {
                                            return <> - </>
                                        }
                                    }
                                }
                            ]}
                            data={invoices}
                            options={{
                                pageSize: 10,
                            }}
                        />
                    </>
                )}
                </div>
            </Paper>
        </Container>
    )
});

