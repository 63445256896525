import {CustomerMwsAccount} from "@mitou/react-library/build";

export const CHANGE_ACCOUNT = 'changeAccount';
export const CHANGE_MARKETPLACE = 'changeMarketplace';
export const CHANGE_ACCOUNT_MARKETPLACE = 'changeAccountMarketplace';


interface ChangeAccountAction {
    type: typeof CHANGE_ACCOUNT;
    accountId: number | null
}
interface ChangeMarketplaceAction {
    type: typeof CHANGE_MARKETPLACE;
    marketplace: string | null
}
interface ChangeAccountMarketplaceAction {
    type: typeof CHANGE_ACCOUNT_MARKETPLACE;
    accountId: number | null
    marketplace: string | null
    accounts: CustomerMwsAccount[]
}

export function changeAccount(accountId: number | null): ChangeAccountAction {
    return { type: CHANGE_ACCOUNT, accountId: accountId };
}

export function changeMarketplace(marketplace: string | null): ChangeMarketplaceAction {
    return { type: CHANGE_MARKETPLACE, marketplace: marketplace };
}

export function changeAccountMarketplace(accountId: number | null, marketplace: string | null, accounts: CustomerMwsAccount[]): ChangeAccountMarketplaceAction {
    return { type: CHANGE_ACCOUNT_MARKETPLACE, accountId: accountId, marketplace: marketplace, accounts: accounts };
}

export type AccountActions = ChangeAccountAction | ChangeMarketplaceAction | ChangeAccountMarketplaceAction;
