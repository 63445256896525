import {ClosableDialogTitle, LogisticLogin, LogisticLoginForm, LogisticLoginTable} from "@mitou/react-library";
import * as React from "react";
import {useEffect, useState} from "react";
import {
    createLogisticLogin,
    deleteLogisticLogin,
    getLogisticLogins,
    updateLogisticLogin
} from "../../Service/LogisticLoginService";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

function LogisticLoginSettings() {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [logisticLogins, setLogisticLogin] = useState<LogisticLogin[] | null>(null);
    const [selectedLogisticLogin, setSelectedLogisticLogin] = useState<LogisticLogin | null>(null);
    const onCancel = () => {
        setDialogOpen(false);
        setSelectedLogisticLogin(null);
    };
    const onCreate = (logisticLogin: LogisticLogin): Promise<void> => {
        return createLogisticLogin(logisticLogin).then(() => {
            setLogisticLogin(null);
            getLogisticLogins().then(setLogisticLogin);
            onCancel();

            return Promise.resolve();
        });
    };
    const onUpdate = (logisticLogin: LogisticLogin): Promise<void> => {
        return updateLogisticLogin(logisticLogin).then(() => {
            setLogisticLogin(null);
            getLogisticLogins().then(setLogisticLogin);
            onCancel();

            return Promise.resolve();
        });
    };
    const onDelete = (logisticLogin: LogisticLogin) => {
        deleteLogisticLogin(logisticLogin).then(() => {
            setLogisticLogin(null);
            getLogisticLogins().then(setLogisticLogin);
            onCancel();
        });
    };
    const createClicked = () => {
        setDialogOpen(true);
        setSelectedLogisticLogin(null);
    };
    const updateClicked = (logisticLogin: LogisticLogin) => {
        setDialogOpen(true);
        setSelectedLogisticLogin(logisticLogin);
    };

    useEffect(() => {
        getLogisticLogins().then(setLogisticLogin);
    }, []);

    return (
        <Grid container>
            <Grid item xs={12} md={8} lg={6}>
                <div>
                    <Button size={"small"} variant={"contained"} onClick={() => createClicked()} color="primary">
                        <FontAwesomeIcon fixedWidth={true} icon={["far", "plus"]}/>
                        {' '}Login hinzufügen
                    </Button>
                </div>
                <LogisticLoginTable logisticLogins={logisticLogins} updateClicked={updateClicked} deleteClicked={onDelete}/>
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth={"md"}>
                    <ClosableDialogTitle onClose={() => setDialogOpen(false)}>
                        Login {selectedLogisticLogin ? 'bearbeiten' : 'hinzufügen'}
                    </ClosableDialogTitle>
                    <DialogContent>
                        <LogisticLoginForm logisticLogin={selectedLogisticLogin} onCancel={onCancel} onCreate={onCreate} onUpdate={onUpdate}/>
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid>
    )
}

export default LogisticLoginSettings;
