import PortalClient from '../Component/PortalClient'
import moment from "moment";

export const chatService = {
    loadData,
    loadTaskData,
    updateReadStatus,
    sendMessage,
    deleteMessage
};

function loadData(from) {
    let params = {};

    if (from) {
        params.from = from.toISOString();
    }

    return PortalClient.get('/chat/data', {params: params}).then(response => {
        const messages = [];

        response.data.forEach(function (apiMessage) {
            messages.push(mapMessageFromApi(apiMessage));
        });

        return Promise.resolve(messages);
    });
}

function loadTaskData(taskId) {
    return PortalClient.get('/chat/taskData/' + taskId).then(response => {
        const messages = [];

        response.data.forEach(function (apiMessage) {
            messages.push(mapMessageFromApi(apiMessage));
        });

        return Promise.resolve(messages);
    });
}

function updateReadStatus() {
    return PortalClient.get('/chat/read').then(response => {

        return Promise.resolve(response.data);
    });
}

function sendMessage(message) {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return PortalClient.post(
            '/chat/send',
            message,
            config
        )
        .then(response => {
            return Promise.resolve(response.data);
    });
}


function deleteMessage(message) {
    return PortalClient.delete('/chat/delete' + message.id).then(response => {

        return Promise.resolve(response.data);
    });
}


function mapMessageFromApi(apiMessage) {
    return {
        id: apiMessage.id,
        key: apiMessage.key,
        author: apiMessage.user ? apiMessage.user : apiMessage.login,
        isOwn: !apiMessage.user,
        messageText: apiMessage.message,
        filePath: apiMessage.filePath,
        fileName: apiMessage.fileName,
        task: apiMessage.task,
        sendAt: moment(apiMessage.createdAt),
        seenAt: apiMessage.seenAt ? moment(apiMessage.seenAt) : null,
    };
}
