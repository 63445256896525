import {connect} from "react-redux";
import {Link, withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import React, {useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {useQuery} from "@apollo/react-hooks";
import moment from "moment";
import ProductCheckerDialog from "./ProductCheckerDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import gql from "graphql-tag/lib/graphql-tag.umd";
import {truncateName} from "../../Helpers/ItemHelper";
import Tendency from "../Tendency";
import {
    formatMoneyByMarketplace,
    Fulfillment,
    FulfillmentLabel,
    PRODUCT_CHECKER_TYPE_LABELS,
    Search,
    Select
} from "@mitou/react-library/build";
import {Title} from "@mitou/react-library";

const GET_PRODUCT_CHECKER_LISTINGS_QUERY = gql`
query getProductCheckerListings(
        $accountId: Int!
        $marketplace: Marketplace!
        $search: String
        $filter: String
        $status: String
        $fullfillment: String
        $page: Int!
    ) {
    getProductCheckerListings(
        accountId: $accountId
        marketplace: $marketplace
        search: $search
        filter: $filter
        status: $status
        fullfillment: $fullfillment
        page: $page
    ) {
        listing {
            id
            name
            sku
            currentQuantity
            fba
            product {
                asin
                updatedAt
                lastChangedAttributes
            }
        }
        orderStats {
            salesCurrentMonth
            salesLastMonth
        }
        total
        page
    }
}
`;

const filters = [
    {
        value: 'all',
        label: 'Alle'
    }, {
        value: 'title',
        label: 'Titel geändert'
    }, {
        value: 'description',
        label: 'Beschreibung geändert'
    }, {
        value: 'image',
        label: 'Bild geändert'
    }, {
        value: 'other',
        label: 'Andere Daten geändert'
    }
];

const states = [
    {
        value: 'all',
        label: 'Alle'
    }, {
        value: 'active',
        label: 'Aktiv'
    }, {
        value: 'inactive',
        label: 'Inaktiv'
    }
];

function getUpdatedAtByFilter(product, filter) {

    switch (filter) {
        case 'title':
            return product.titleChangedAt;

        case 'description':
            return product.descriptionChangedAt;

        case 'image':
            return product.imageChangedAt;

        case 'other':
            return product.othersChangedAt;

        default:
            return product.updatedAt;
    }
}

function ProductChecker(props) {
    const {classes, accountId, marketplace} = props;
    const [filter, setFilter] = useState('all');
    const [status, setStatus] = useState('all');
    const [fullfillment, setFulfillment] = useState('all');
    const [search, setSearch] = useState('');
    const [listing, setListing] = useState(null);

    const {loading, data} = useQuery(GET_PRODUCT_CHECKER_LISTINGS_QUERY, {
        variables: {
            accountId: accountId,
            marketplace: marketplace,
            search: search === '' ? null : search,
            filter: filter,
            status: status,
            fullfillment: fullfillment,
            page: 1
        },
    });

    return (
        <React.Fragment>
            <Title>Produkt-Checker</Title>
            <div className={classes.pillContainer}>
                <Select
                    id="filter-select"
                    label="Art"
                    value={filter}
                    values={filters}
                    onChange={setFilter}
                />
                <Select
                    id="status-select"
                    label="Status"
                    value={status}
                    values={states}
                    onChange={setStatus}
                />
                <Fulfillment value={fullfillment} onChange={setFulfillment}/>
                <Search value={search} onChange={setSearch}/>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Artikel</TableCell>
                        <TableCell>Versand</TableCell>
                        <TableCell>ASIN</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Bestand</TableCell>
                        <TableCell>Verkäufe (30 Tage)</TableCell>
                        <TableCell>Zuletzt geändert</TableCell>
                        <TableCell>Zuletzt geändert am</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading &&
                    <TableRow>
                        <TableCell colSpan={7}>
                            <Box className={classes.progressContainer}>
                                <CircularProgress className={classes.progress}/>
                            </Box>
                        </TableCell>
                    </TableRow>
                    }
                    {!loading && data.getProductCheckerListings.map((entry) => (
                        <TableRow key={entry.listing.id} hover>
                            <TableCell>
                                <Link onClick={() => setListing(entry.listing)}>
                                    <FontAwesomeIcon icon={["far", "search-plus"]} /> {truncateName(entry.listing.name, 50)}
                                </Link>
                            </TableCell>
                            <TableCell><FulfillmentLabel fba={entry.listing.fba}/></TableCell>
                            <TableCell>{entry.listing.product.asin}</TableCell>
                            <TableCell>{entry.listing.sku}</TableCell>
                            <TableCell>{entry.listing.currentQuantity}</TableCell>
                            <TableCell>
                                {entry.orderStats &&
                                    <React.Fragment>
                                        <Tendency current={entry.orderStats.salesCurrentMonth} before={entry.orderStats.salesLastMonth}/> {formatMoneyByMarketplace(entry.orderStats.salesCurrentMonth, marketplace)}
                                    </React.Fragment>
                                }
                                {!entry.orderStats &&
                                    <span>-</span>
                                }
                            </TableCell>
                            <TableCell>{entry.listing.product.lastChangedAttributes ? getLastChangedTitle(entry.listing.product.lastChangedAttributes) : '-' }</TableCell>
                            <TableCell>{moment(getUpdatedAtByFilter(entry.listing.product, filter)).format('DD.MM.YYYY HH:mm')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            { listing &&
                <ProductCheckerDialog listing={listing} onClose={() => setListing(null)}/>
            }
        </React.Fragment>
    );
}

function getLastChangedTitle(attributes) {
    let titles = [];

    attributes.forEach((attribute) => {
        if (attribute && PRODUCT_CHECKER_TYPE_LABELS[attribute]) {
            titles.push(PRODUCT_CHECKER_TYPE_LABELS[attribute])
        }

    });

    return titles.join(', ');
}

function mapStateToProps(state) {
    return {...state.account};
}

export default connect(mapStateToProps)(withStyles(mitouStyles)(ProductChecker));
