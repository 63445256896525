
export function task(state = null, action: any) {
    switch (action.type) {
        case 'changeSelectedTask':
            return action.task;

        default:
            return state
    }
}
