import {green, grey, orange} from "@material-ui/core/colors";
import {createStyles, Theme} from "@material-ui/core";

export const mitouStyles = (theme: Theme) => createStyles({
        flexContainer: {
            display: 'flex',
        },
        flexGrow: {
            flexGrow: 1
        },
        navInputLabel: {
            color: 'inherit',
        },
        navInput: {
            color: 'inherit',
        },
        dashboardRelativeGridItem: {
            position: 'relative'
        },
        dashboardContainer: {
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(4),
        },
        dashboardPaper: {
            padding: theme.spacing(1),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        dashboardRevenueBackground: {
            background: '#3c6168',
        },
        dashboardAccountStatusGradient: {
            background: 'linear-gradient(to right, #147985 0%,#3b616a 100%);'
        },
        dashboardAdvertisingGradient: {
            background: 'linear-gradient(to right, #149096 0%,#137a86 100%);'
        },
        dashboardFixedHeight: {
            height: 360,
        },
        dashboardFullHeight: {
            height: '100%',
        },
        dashboardTaskTimelinePaper: {
            padding: theme.spacing(0),
            height: 345,
        },
        dashboardTaskTimelineContainer: {
            display: 'flex',
            height: '100%',
        },
        dashboardTaskTimelineControl: {
            position: 'relative',
            width: 30,
            textAlign: 'center',
            backgroundColor: theme.palette.primary.main,
            paddingTop: 160,
            cursor: 'pointer',
            color: '#fff',
        },
        progress: {
            margin: theme.spacing(2)
        },
        progressInverted: {
            margin: theme.spacing(2),
            color: '#fff'
        },
        progressContainer: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        pillContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
        },
        pill: {
            background: theme.palette.text.primary,
            color: '#fff',
            margin: theme.spacing(0, .4),
            '&:hover': {
                background: '#cc3d11',
                color: '#fff',
            },
            '&:focus': {
                background: '#cc3d11',
                color: '#fff',
            },
        },
        pillActive: {
            background: '#cc3d11',
            color: '#fff',
            margin: theme.spacing(0, .4),
            '&:hover': {
                background: '#cc3d11',
                color: '#fff',
            },
            '&:focus': {
                background: '#cc3d11',
                color: '#fff',
            },
        },
        taskMonthContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            padding: theme.spacing(1),
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-end'
        },
        taskMonthTabAppBar: {
            width: 'auto',
            boxShadow: 'none',
            marginRight: theme.spacing(4),
            zIndex: 1000,
        },
        taskMonth: {
            backgroundColor: '#14a1a1',
            color: '#fff',
        },
        taskMonthSelected: {
            backgroundColor: '#ff8f00',
            color: '#fff',
        },
        taskMonthDisabled: {
            backgroundColor: '#6b6e70',
            color: '#fff',
        },
        taskMonthTab: {
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            marginRight: theme.spacing(1)
        },
        taskTimelineTitle: {
            flexGrow: 1,
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(5),
        },
        taskMonthPillActive: {
        },
        taskMonthPillDisabled: {
        },
        invertedTable: {
            borderColor: '#000',
            color: ''
        },
        labelSuccess: {
            color: green[600],
        },
        labelDanger: {
            color: theme.palette.error.main,
        },
        labelWarning: {
            color: orange[600],
        },
        labelPrimary: {
            color: grey[400],
        },
        invertedChart: {
            color: '#fff',
        },
        buttonCreate: {
            backgroundColor: '#8cc53d',
            marginRight: theme.spacing(2),
            '&:hover': {
                background: '#70a91e',
            },
            '&:focus': {
                background: '#70a91e',
            },
        },
        invertedChartTooltip: {
            backgroundColor: theme.palette.text.primary,
        },
        taskCard: {
            cursor: 'pointer',
            width: 250,
            margin: theme.spacing(1),
            backgroundColor: '#e6e6e6',
            position: 'absolute',
            overflow: 'visible',
            top: -32,
            left: 12,
            zIndex: 100
        },
        taskCardOdd: {
            top: -133,
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 33,
                width: 0,
                height: 0,
                border: '10px solid transparent',
                borderTopColor: '#e6e6e6',
                borderBottom: 0,
                marginLeft: -10,
                marginBottom: -10,
            }
        },
        taskCardEven: {
            top: 20,
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 33,
                width: 0,
                height: 0,
                border: '10px solid transparent',
                borderBottomColor: '#e6e6e6',
                borderTop: 0,
                marginLeft: -10,
                marginTop: -10,
            }
        },
        taskCardExpanded: {
            width: 500,
            zIndex: 101,
        },
        taskCardAvatar: {
            width: 25,
            height: 25,
            fontSize: 12
        },
        taskCardHeader: {
            height: 27,
            padding: theme.spacing(1),
        },
        taskCardContent: {
            padding: theme.spacing(1),
            paddingTop: 0,
            paddingBottom: 0
        },
        taskCardActions: {
            padding: 0,
            justifyContent: 'flex-end',
        },
        taskCardAction: {
            color: '#118f94',
            marginLeft: 10,
        },
        taskCardActionRequired: {
            color: theme.palette.error.main,
            marginLeft: 10,
        },
        taskTimelineScroll: {
            height: '100%',
            width: '100%',
            overflow: 'auto',
        },
        taskTimeline: {
            padding: '135px 0',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            marginTop: 40,
            flexGrow: 1,
        },
        taskTimelineDateContainer: {
            height: '100%',
            position: 'relative',
            display: 'inline-block',
        },
        taskTimelineDateLabel: {
            borderRadius: 10,
            background: '#14a1a1',
            color: '#fff',
            padding: 4,
            fontWeight: 600,
            fontSize: 12,
            position: 'absolute',
            top: 1,
            right: 0,
        },
        taskTimelinePoint: {
            backgroundColor: theme.palette.text.primary,
            color: '#fff',
            position: 'absolute',
            left: 40,
            width: 26,
            height: 20,
            paddingTop: 6,
            fontSize: 12,
            textAlign: 'center',
            borderRadius: '50%',
            top: -1
        },
        taskTimelineEntry: {
            position: 'relative',
            display: 'inline-block',
            whiteSpace: 'normal',
            width: 132,
            height: 5,
            background: '#ddd',
        },
        taskTimelineDummyEntry: {
            width: 182,
        },
        expandTaskDesc: {
            height: '3em',
            overflow: 'hidden',
            color: '#5f5f61',
            '& p': {
                margin: 0,
            }
        },
        expandTaskDescOpen: {
            minHeight: '3em',
            height: 'initial'
        },
        chatWindow: {
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'stretch',
            flexDirection: 'column'
        },
        chatForm: {
            display: 'flex',
            alignItems: 'flex-end',
            marginTop: theme.spacing(1),
        },
        chatMessageInput: {
            flexGrow: 1,
        },
        chatMessageSendAt: {
            fontSize: 10,
            color: '#6b6e70',
            position: 'absolute',
            right: 5,
            bottom: 5,
        },
        chatMessageFileUploadContainer: {
            marginTop: theme.spacing(1),
            fontSize: 12,
        },
        chatMessageList: {
            height: '100%',
            width: '100%',
            overflow: 'auto',
        },
        chatMessageRow: {
            display: 'flex',
            margin: theme.spacing(2, 0)
        },
        chatMessage: {
            position: 'relative',
            overflow: 'visible',
            padding: theme.spacing(2),
            flexGrow: 1,
        },
        chatMessageOwn: {
            backgroundColor: '#f3f3f3',
        },
        chatMessageOther: {
            backgroundColor: '#ffd7b0',
        },
        chatMessageAvatar: {
        },
        chatMessageAvatarOwn: {
            marginLeft: theme.spacing(1),
        },
        chatMessageAvatarOther: {
            marginRight: theme.spacing(1),
        },
        chatMessageFileLink: {
            marginTop: theme.spacing(1),
            fontSize: 12,
        },
        contactPersonContainer: {
            marginTop: theme.spacing(2),
            display: 'flex'
        },
        contactPersonAvatar: {
            width: 100,
            height: 100,
            marginRight: theme.spacing(3),
        },
        mediumDialog: {
            minWidth: '50%',
        },
        taskDialogAvatar: {
            width: 50,
            height: 50,
            fontSize: 20,
            marginRight: theme.spacing(2)
        },
        taskDialogTitle: {
            fontSize: 20,
            marginTop: theme.spacing(1)
        },
        navEntries: {
            margin: theme.spacing(0, 1),
        },
        iconButtonBadge: {
            bottom: 9,
            right: 9
        },
        noWrap: {
            whiteSpace: 'nowrap'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        textFieldFormLabel: {
            marginTop: 15,
        },
        shortTextField: {
            width: 100,
        },
        formControlFormLabel: {
            marginTop: 15,
            marginBottom: 5,
        },
        hidden: {
            display: 'none',
        },
        buttonTopSpace: {
            marginTop: theme.spacing(1),
        },
        pdfViewContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingTop: '80px',
        },
        pdfDownloadButton: {
            margin: '20px 20px 20px 100px',
            color: 'white',
            backgroundColor: '#3a6168',
            '&:hover': {
                backgroundColor: '#294549',
            },
        },
        invoiceTableContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        invoiceTablePaper: {
            flex: '0.25 1 auto',
        },
        link: {
            color: '#3a6168',
        },
        revenuePaper: {
            padding: '5px 5px 5px 10px',
        },
        revenueListEntry: {
            '&:before': {
                top: '-1px',
                left: '0px',
                right: '0px',
                height: '1px',
                content: '""',
                opacity: '1',
                position: 'absolute',
                backgroundColor: 'rgba(0,0,0,0.12)',
            },
            minHeight: '48px',
        },
        returnExpansionPanel: {
            boxShadow: 'none',
            padding: '0px 16px 0px 16px',
        },
        textRight: {
            textAlign: 'right',
        },
        noPaddingTopBottom: {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        noPaddingLeftRight: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
        totalEntry: {
            minHeight: '48px',
            paddingLeft: '16px',
            paddingRight: '16px',
            alignItems: 'center',
            backgroundColor: '#3a6168',
            color: 'white'
        },
        totalEntryReturn: {
            color: 'red',
        },
        totalReturnNumber: {
            paddingRight: '36px',
        },
        returnListExpandable: {
            width: '100%',
        },
        totalRevenuePaper: {
            backgroundColor: '#3a6168',
            color: 'white',
        },
        textCenter: {
            textAlign: 'center',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
});
