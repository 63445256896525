import PortalClient from '../Component/PortalClient'
import {LogisticLogin} from "@mitou/react-library/build";

const basePath = '/customer/logistic-login/';

interface GetLogisticLoginsResponse {
    data: LogisticLogin[];
}
interface CreateLogisticLoginsResponse {
    data: LogisticLogin;
}
interface UpdateLogisticLoginsResponse {
    data: LogisticLogin;
}

export function getLogisticLogins(): Promise<LogisticLogin[]> {
    return PortalClient.get(basePath).then(({data}: GetLogisticLoginsResponse) => Promise.resolve(data));
}
export function createLogisticLogin(logisticLogin: LogisticLogin): Promise<LogisticLogin> {
    return PortalClient.post(basePath, logisticLogin).then(({data}: CreateLogisticLoginsResponse) => Promise.resolve(data));
}
export function updateLogisticLogin(logisticLogin: LogisticLogin): Promise<LogisticLogin> {
    const path = basePath + logisticLogin.id;

    return PortalClient.put(path, logisticLogin).then(({data}: UpdateLogisticLoginsResponse) => Promise.resolve(data));
}
export function deleteLogisticLogin(logisticLogin: LogisticLogin): Promise<void> {
    const path = basePath + logisticLogin.id;

    return PortalClient.delete(path).then(() => Promise.resolve());
}
