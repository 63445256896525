import PortalClient from '../Component/PortalClient'
import {AsinLogisticData} from "@mitou/react-library";

const basePath = '/amazon-product/asin-logistic-data';

export function getByAsin(asin: string): Promise<AsinLogisticData | null> {
    const path = basePath + '/' + asin;

    return PortalClient.get<AsinLogisticData>(path)
        .then(({data}) => Promise.resolve(data))
        .catch((error) => error.response && error.response.status === 404 ? Promise.resolve(null) : Promise.reject(error));
}
