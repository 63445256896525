/* eslint-disable no-script-url */

import React from 'react';
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {ThemeProvider} from '@material-ui/styles';
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import {mitouInvertedTheme} from "../../Theme/MitouTheme";
import moment from "moment";
import Tendency from "../Tendency";
import {formatMoney, formatMoneyByMarketplace} from "@mitou/react-library/build";
import {connect} from "react-redux";
import {Title} from "@mitou/react-library";

class Revenue extends React.Component {

    constructor(props) {
        super(props);

        this.renderTable = this.renderTable.bind(this);
        this.renderChart = this.renderChart.bind(this);
        this.renderTooltipValue = this.renderTooltipValue.bind(this);
    }

    renderTable() {
        const {data} = this.props;
        const stats = data.statistics;

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Heute</TableCell>
                        <TableCell>Gestern</TableCell>
                        <TableCell>7 Tage</TableCell>
                        <TableCell>30 Tage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell><Tendency current={stats.salesToday} before={stats.salesYesterdayPartial}/> {formatMoney(stats.salesToday, 'EUR')}</TableCell>
                        <TableCell><Tendency current={stats.salesYesterday} before={stats.salesDayBeforeYesterday}/> {formatMoney(stats.salesYesterday, 'EUR')}</TableCell>
                        <TableCell><Tendency current={stats.salesCurrent7Days} before={stats.salesLast7Days}/> {formatMoney(stats.salesCurrent7Days, 'EUR')}</TableCell>
                        <TableCell><Tendency current={stats.salesCurrent30Days} before={stats.salesLast30Days}/> {formatMoney(stats.salesCurrent30Days, 'EUR')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Tendency current={stats.quantityToday} before={stats.quantityYesterdayPartial}/> {stats.quantityToday} Stück</TableCell>
                        <TableCell><Tendency current={stats.quantityYesterday} before={stats.quantityDayBeforeYesterday}/> {stats.quantityYesterday} Stück</TableCell>
                        <TableCell><Tendency current={stats.quantityCurrent7Days} before={stats.quantityLast7Days}/> {stats.quantityCurrent7Days} Stück</TableCell>
                        <TableCell><Tendency current={stats.quantityCurrent30Days} before={stats.quantityLast30Days}/> {stats.quantityCurrent30Days} Stück</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    renderTooltipValue(value, name, props) {
        const {marketplace} = this.props;
        let formattedValue = value;

        if (props.dataKey === 'date') {
            formattedValue = moment(value).format('DD.MM.YY');
        } else if (props.dataKey === 'sales') {
            formattedValue = formatMoneyByMarketplace(value, marketplace);
        } else if (props.dataKey === 'quantity') {
            formattedValue = value + ' Stück';
        }

        return [formattedValue, name];
    }

    renderChart() {
        const {data, classes} = this.props;

        return (
            <ResponsiveContainer width="100%" height={230} className={classes.invertedChart}>
                <LineChart data={data.current}
                           margin={{top: 10, right: 5, left: 5, bottom: 0}}>
                    <XAxis dataKey="date" stroke="#d9d9d9" tickFormatter={(tickItem) => moment(tickItem).format('DD.MM.YY')}/>
                    <YAxis yAxisId="sales" stroke="#5999d4" />
                    <YAxis yAxisId="quantity" stroke="#eb7d30" orientation="right" />
                    <Legend />
                    <Tooltip wrapperStyle={{color: '#3a6168'}} formatter={this.renderTooltipValue} labelFormatter={(date) => moment(date).format('DD.MM.YY')}/>
                    <Line name="Umsatz" yAxisId="sales" type="monotone" dataKey="sales" stroke="#5999d4" activeDot={{r: 8}}/>
                    <Line name="Stückzahl" yAxisId="quantity" type="monotone" dataKey="quantity" stroke="#eb7d30" />
                </LineChart>
            </ResponsiveContainer>
        );
    }

    render() {
        return (
            <ThemeProvider theme={mitouInvertedTheme}>
                <Title>Umsätze</Title>
                <React.Fragment>
                    {this.renderTable()}
                    {this.renderChart()}
                </React.Fragment>
            </ThemeProvider>
        );
    }
}


function mapStateToProps(state) {
    return {...state.account};
}
const withStylesRevenue = connect(mapStateToProps)(withStyles(mitouStyles)(Revenue));

export {withStylesRevenue as Revenue};
