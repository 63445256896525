import React from "react";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {UserAvatar} from "../UserAvatar";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";

class TaskCard extends React.Component {
    render() {
        const {classes, task, taskCounter, clickHandler} = this.props;
        const user = task.completedBy ? task.completedBy : task.assignedTo;
        const desc = task.portalDescription ? task.portalDescription : task.description;

        return (
            <Card className={clsx(classes.taskCard, {
                [classes.taskCardEven]: taskCounter % 2 === 0,
                [classes.taskCardOdd]: taskCounter % 2 !== 0
                  })
                } onClick={() => clickHandler(task)}>
                <CardHeader
                    avatar={
                        <UserAvatar user={user} className={classes.taskCardAvatar} />
                    }
                    title={
                        <Typography variant={"body1"} color={"textPrimary"} component={"span"}>{task.service ? task.service.name : "Aufgabe"}</Typography>
                    }
                    className={classes.taskCardHeader}
                />
                <CardContent className={classes.taskCardContent}>
                    <Typography variant="body2"
                                color="textSecondary"
                                component="div"
                                className={classes.expandTaskDesc}
                                dangerouslySetInnerHTML={{ __html: desc}} />
                </CardContent>
                <CardActions className={classes.taskCardActions} disableSpacing>
                    { task.pending && task.needsCustomerApproval &&
                    <Tooltip title={'Bestätigung erforderlich'}>
                        <IconButton size={"small"} className={classes.taskCardActionRequired}>
                            <FontAwesomeIcon icon={["far", "exclamation-circle"]} />
                        </IconButton>
                    </Tooltip>
                    }
                    <Badge className={classes.taskCardAction} badgeContent={task.messages.length} color="primary">
                        <FontAwesomeIcon icon={["far", "comments"]} />
                    </Badge>
                    <IconButton size={"small"} className={classes.taskCardAction}>
                        <FontAwesomeIcon icon={["far", "search-plus"]} />
                    </IconButton>
                </CardActions>
            </Card>
        );
    }
}

const withStylesTaskTimeline = withStyles(mitouStyles)(TaskCard);

export {withStylesTaskTimeline as TaskCard};
