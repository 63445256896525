import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import React from "react";
import OutOfStock from "../Component/OutOfStock/OutOfStock";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Container from "@material-ui/core/Container";

function OutOfStockPage({classes}) {
    const fullHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFullHeight);

    return (
        <Container maxWidth={false} className={classes.dashboardContainer}>
            <Paper className={fullHeightPaper}>
                <OutOfStock/>
            </Paper>
        </Container>
    )
}

export default withStyles(mitouStyles)(OutOfStockPage);
