import React from 'react';
import {Route, Router} from 'react-router-dom'
import {ThemeProvider} from '@material-ui/styles';
import Login from "./Page/Login";
import {mitouTheme} from "./Theme/MitouTheme";
import history from './Helpers/History';
import {library} from '@fortawesome/fontawesome-svg-core'
import {Provider} from "react-redux";
import {ApolloProvider} from '@apollo/react-hooks';
import {store} from "./Helpers/Store";
import {
    faAngleLeft,
    faAngleRight,
    faArrowCircleDown,
    faArrowCircleUp,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faChevronSquareDown,
    faChevronSquareUp,
    faCogs,
    faComments,
    faEnvelope,
    faExclamationCircle,
    faExclamationSquare,
    faFileInvoice,
    faMinusCircle,
    faPaperclip,
    faPaperPlane,
    faPencil,
    faPlus,
    faQuestionCircle,
    faSave,
    faSearch,
    faSearchPlus,
    faSignOut,
    faSpinner,
    faSquare,
    faTasks,
    faTimes,
    faTimesSquare,
    faTrashAlt,
    faUser,
} from '@fortawesome/pro-regular-svg-icons'
import {graphClient} from "./Component/GraphClient";
import {isPushNotificationSupported, registerServiceWorker} from "./Helpers/PushNotifications";
import PrivatePage from "./Page/PrivatePage";
import UserService from "./Service/UserService";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

library.add(
    faAngleLeft,
    faAngleRight,
    faArrowCircleDown,
    faArrowCircleUp,
    faCheckCircle,
    faCheckSquare,
    faChevronSquareDown,
    faChevronSquareUp,
    faChevronLeft,
    faChevronRight,
    faExclamationCircle,
    faExclamationSquare,
    faPaperclip,
    faPaperPlane,
    faPencil,
    faPlus,
    faQuestionCircle,
    faSearch,
    faSearchPlus,
    faSignOut,
    faSquare,
    faTasks,
    faTrashAlt,
    faTimesSquare,
    faComments,
    faEnvelope,
    faTimes,
    faMinusCircle,
    faSpinner,
    faSave,
    faUser,
    faFileInvoice,
    faCheck,
    faCogs,
);

function App() {
    const isLoggedIn = UserService.isLoggedIn();
    if (isPushNotificationSupported()) {
        registerServiceWorker()
            .catch((response) => {
                console.log(response);
            });
    }
    return (
        <ApolloProvider client={graphClient}>
            <Provider store={store}>
                <ThemeProvider theme={mitouTheme}>
                    <Router history={history}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale="de-DE">
                            <Route exact path="/login" component={Login}/>
                            <PrivatePage isLoggedIn={isLoggedIn}/>
                        </MuiPickersUtilsProvider>
                    </Router>
                </ThemeProvider>
            </Provider>
        </ApolloProvider>
    );
}

export default App;
