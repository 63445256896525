import PortalClient from '../Component/PortalClient';

function getRefundCasesByInvoiceId(invoiceId) {
    return PortalClient.get(`/refund/cases/get/${invoiceId}`)
        .then(({data}) => Promise.resolve(data));
}

export const refundCaseService = {
    getRefundCasesByInvoiceId,
};

