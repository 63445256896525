import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from '@material-ui/core/Tooltip';

export class UserAvatar extends React.Component {

    render() {
        const {user, className} = this.props;

        const color = user.color ? user.color : '#3a6168';
        const imageSrc = user.image ? user.image : null;
        const initials = user.initials ? user.initials : user.firstName.substr(0, 1) + user.lastName.substr(0, 1);
        let avatar;

        if (user.image) {
            avatar = <Avatar src={imageSrc} className={className} />;
        } else {
            avatar = <Avatar style={{backgroundColor: color}} className={className}>{initials}</Avatar>;
        }

        return (
            <Tooltip title={user.firstName + ' ' + user.lastName}>
                {avatar}
            </Tooltip>
        );
    }
}
