import React from "react";
import nl2br from "react-nl2br";
import clsx from 'clsx';
import {Paper, Box, withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import {UserAvatar} from "../UserAvatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {taskActions} from "../../Actions/task";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";

class Message extends React.Component {

    render() {
        const {showTask, dispatch, classes, message} = this.props;

        return (
            <Box className={classes.chatMessageRow}>
                { !message.isOwn &&
                    <UserAvatar user={message.author} className={clsx(classes.chatMessageAvatar, classes.chatMessageAvatarOther)} />
                }
                <Paper className={clsx(classes.chatMessage, {
                    [classes.chatMessageOther]: !message.isOwn,
                    [classes.chatMessageOwn]: message.isOwn
                })}>
                    {nl2br(message.messageText)}
                    { message.filePath &&
                    <div className={classes.chatMessageFileLink}>
                        <a href={message.filePath} target={"_blank"}><FontAwesomeIcon fixedWidth={true} icon={["far", "paperclip"]} /> {message.fileName}</a>
                    </div>
                    }
                    {showTask && message.task &&
                    <div className={classes.chatMessageFileLink}>
                        { message.task.key &&
                        <Chip label={message.task.key} color={"primary"} />
                        }
                        <Button onClick={() => dispatch(taskActions.changeSelectedTask(message.task))} color="primary" size={"small"}>
                            <FontAwesomeIcon fixedWidth={true} icon={["far", "tasks"]}/>
                            Aufgabe anzeigen
                        </Button>
                    </div>
                    }
                    <div className={classes.chatMessageSendAt}>
                        <Tooltip title={message.sendAt.format('DD.MM.YYYY HH:mm:ss')}>
                            <div>{message.sendAt.locale('de').fromNow()}</div>
                        </Tooltip>
                    </div>
                </Paper>
                { message.isOwn &&
                <UserAvatar user={message.author} className={clsx(classes.chatMessageAvatar, classes.chatMessageAvatarOwn)} />
                }
            </Box>
        );
    }
}

const connectedWithStylesMessage = connect()(withStyles(mitouStyles)(Message));

export {connectedWithStylesMessage as Message};
