import {AccountActions, CHANGE_ACCOUNT, CHANGE_ACCOUNT_MARKETPLACE, CHANGE_MARKETPLACE} from "../Actions/account";
import {CustomerMwsAccount} from "@mitou/react-library/build";


interface AccountReducerState {
    accountId: number | null
    marketplace: string | null
    accounts: CustomerMwsAccount[]
    loaded: boolean
}

const defaultState: AccountReducerState = {
    accountId: null,
    marketplace: null,
    accounts: [],
    loaded: false,
};

export function account(state: AccountReducerState = defaultState, action: AccountActions) {
    switch (action.type) {
        case CHANGE_ACCOUNT:
            return {
                ...state,
                accountId: action.accountId
            };

        case CHANGE_MARKETPLACE:
            return {
                ...state,
                marketplace: action.marketplace
            };

        case CHANGE_ACCOUNT_MARKETPLACE:
            return {
                ...state,
                accountId: action.accountId,
                marketplace: action.marketplace,
                accounts: action.accounts,
                loaded: true,
            };

        default:
            return {...state};
    }
}
