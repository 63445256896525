/* eslint-disable no-script-url */

import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Chip from "@material-ui/core/Chip";
import {mitouInvertedTheme} from "../../Theme/MitouTheme";
import {ThemeProvider} from '@material-ui/styles';
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import {Title} from "@mitou/react-library";

class Advertising extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rangeFilter: 'current_month'
        };

        this.filters = [
            {
                value: 'current_month',
                label: 'aktueller Monat'
            }, {
                value: 'last_month',
                label: 'letzter Monat'
            }, {
                value: 'all',
                label: 'gesamte Laufzeit'
            }
        ];
    }

    changeRangeFilter(rangeFilter) {
        this.setState({rangeFilter: rangeFilter});
    }

    renderTable() {
        const {data} = this.props;
        const {rangeFilter} = this.state;

        const rangeStatistics = data[rangeFilter] ? data[rangeFilter] : [];

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Land</TableCell>
                        <TableCell>Impressions</TableCell>
                        <TableCell>Klicks</TableCell>
                        <TableCell>Ausgaben</TableCell>
                        <TableCell>Bestellungen</TableCell>
                        <TableCell>Umsatz</TableCell>
                        <TableCell>∅ CPC</TableCell>
                        <TableCell>ACoS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rangeStatistics.map(statistics => (
                        <TableRow key={statistics.profile.marketplace}>
                            <TableCell>{statistics.profile.marketplace}</TableCell>
                            <TableCell align={"right"}>{Advertising.renderFloat(statistics.impressionsOnlyOwn)}</TableCell>
                            <TableCell align={"right"}>{Advertising.renderFloat(statistics.clicksOnlyOwn)}</TableCell>
                            <TableCell align={"right"}>{Advertising.renderMoney(statistics.costOnlyOwn, statistics.profile.currencyCode)}</TableCell>
                            <TableCell align={"right"}>{Advertising.renderFloat(statistics.conversionsOnlyOwn)}</TableCell>
                            <TableCell align={"right"}>{Advertising.renderMoney(statistics.sales, statistics.profile.currencyCode)}</TableCell>
                            <TableCell align={"right"}>{Advertising.renderMoney(statistics.cpcOnlyOwn, statistics.profile.currencyCode)}</TableCell>
                            <TableCell align={"right"}>{Advertising.renderPercentage(statistics.acosOnlyOwn)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }

    static renderPercentage(percent) {
        if (percent !== 0) {
            percent /= 100;
        }
        percent = new Intl.NumberFormat('de-DE', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(percent);

        return (
            <span>
                {percent}
            </span>
        );
    }

    static renderMoney(amount, currencyCode) {
        amount = new Intl.NumberFormat('de-DE', { style: 'currency', currency: currencyCode }).format(amount);

        return (
            <span>
                {amount}
            </span>
        );
    }

    static renderFloat(amount) {
        amount = new Intl.NumberFormat('de-DE').format(amount);

        return (
            <span>
                {amount}
            </span>
        );
    }

    render() {
        const {classes} = this.props;
        const {rangeFilter} = this.state;

        return (
            <ThemeProvider theme={mitouInvertedTheme}>
                <Title>Advertising</Title>
                <React.Fragment>
                    <div className={classes.pillContainer}>
                        {this.filters.map(filter => (
                            <Chip key={filter.value} label={filter.label}
                                  className={filter.value === rangeFilter ? classes.pillActive : classes.pill}
                                  onClick={() => this.changeRangeFilter(filter.value)}/>
                        ))}
                    </div>
                    {this.renderTable()}
                </React.Fragment>
            </ThemeProvider>
        );
    }
}

const withStylesAdvertising = withStyles(mitouStyles)(Advertising);

export {withStylesAdvertising as Advertising};
