import React from "react";
import {Box, withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {Message} from "./Message";

class MessageList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataLoaded: false,
            messages: [],
            error: false,
            lastRequest: null,
            lastRequestStart: null,
            scrolledToBottom: true,
        };

        this.requestTimeout = null;
        this.isComponentMounted = false;

        this.chatMessageListRef = React.createRef();

        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleFailed = this.handleFailed.bind(this);
        this.handleFinally = this.handleFinally.bind(this);
        this.requestMessages = this.requestMessages.bind(this);
        this.onScrollHandler = this.onScrollHandler.bind(this);
    }

    handleSuccess(newMessages) {
        if (!this.isComponentMounted) {
            return;
        }
        const {scrolledToBottom, lastRequestStart, messages} = this.state;
        const {onLoad} = this.props;

        newMessages.forEach(function (newMessage) {
            let found = false;
            messages.forEach(function (message) {
                if (message.id === newMessage.id) {
                    found = true;
                    return false;
                }
            });
            if (!found) {
                messages.push(newMessage);
            }
        });
        if (onLoad) {
            onLoad();
        }
        this.setState({dataLoaded: true, messages: messages, error: false, lastRequest: lastRequestStart});
        if (scrolledToBottom) {
            this.chatMessageListRef.current.scrollTop = this.chatMessageListRef.current.scrollHeight;
        }
    }

    handleFailed() {
        if (!this.isComponentMounted) {
            return;
        }

        this.setState({dataLoaded: false, data: null, error: true});
    }

    handleFinally() {
        if (this.isComponentMounted) {
            this.requestTimeout = setTimeout(() => this.requestMessages(), 5000);
        }
    }

    componentDidMount() {
        this.isComponentMounted = true;
        this.requestMessages();
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
        if (this.requestTimeout !== null) {
            clearTimeout(this.requestTimeout);
        }
    }

    requestMessages() {
        if (!this.isComponentMounted) {
            return;
        }
        const {loadData} = this.props;
        const {lastRequest} = this.state;

        loadData(lastRequest).then(this.handleSuccess).catch(this.handleFailed).finally(this.handleFinally);
        this.setState({lastRequestStart: moment()});
    }

    onScrollHandler(event) {
        const scrolledToBottom = event.target.scrollHeight <= event.target.scrollTop + event.target.offsetHeight;

        this.setState({scrolledToBottom: scrolledToBottom});
    }

    render() {
        const {showTask, classes} = this.props;
        const {dataLoaded, messages} = this.state;

        return (
            <Box ref={this.chatMessageListRef} className={classes.chatMessageList} onScroll={this.onScrollHandler}>
                {!dataLoaded &&
                <Box className={classes.progressContainer}>
                    <CircularProgress className={classes.progress}/>
                </Box>
                }
                {dataLoaded && messages.map(message => (
                    <Message showTask={showTask} key={message.id} message={message} />
                ))}
            </Box>
        );
    }
}

const withStylesMessageList = withStyles(mitouStyles)(MessageList);

export {withStylesMessageList as MessageList};
