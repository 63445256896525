import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import UserService from '../Service/UserService'
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

function LoginPage({classes}) {
    const [errorText, setErrorText] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();

        if (username && password) {
            UserService.login(username, password)
                .then(() => {
                    window.location.replace('/');
                })
                .catch(() => {
                    setErrorText('Bitte geben Sie Benutzernamen und Passwort an!');
                });
        } else {
            setErrorText('Bitte geben Sie Benutzernamen und Passwort an!');
        }
    };

    useEffect(() => {
        UserService.logout();
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h3" color="primary" align="center" gutterBottom>
                    Log-In
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    {errorText &&
                    <Typography component="h1" variant="h5" color="error" gutterBottom>
                        {errorText}
                    </Typography>
                    }
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Benutzername"
                        name="username"
                        autoComplete="username"
                        onChange={(e) => setUsername(e.target.value)}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Passwort"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Einloggen
                    </Button>
                </form>
            </div>
        </Container>
    );
}

export default withStyles(styles)(LoginPage);
