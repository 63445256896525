import React, {useEffect, useState} from 'react';
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import {refundCaseService} from "../Service/RefundCaseService";
import Grid from "@material-ui/core/Grid";
import CaseTable from "../Component/Refund/CaseTable";

export default withStyles(mitouStyles)(function RefundDocumentPage(props) {
    const { classes } = props;
    const fullHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFullHeight);

    const [casesLoaded, setCasesLoaded] = useState(false);
    const [cases, setCases] = useState([]);
    const [reversals, setReversals] = useState([]);
    const [reversalsLoaded, setReversalsLoaded] = useState(false);

    useEffect(() => {
        const { documentId } = props.match.params;
        refundCaseService.getRefundCasesByInvoiceId(documentId).then(({ caseStats, reversalsPrevious }) => {
            prepCases(caseStats);
            prepReversals(reversalsPrevious);
        });
    }, [props.match.params]);

    const prepCases = (caseStats) => {
        caseStats = Object.values(caseStats);
        caseStats = caseStats.filter((caseStat) => caseStat.reimbursementSum !== 0);
        caseStats = caseStats.map((caseStat) => {
            return {
                'date': caseStat.case.successful_at,
                'caseId': caseStat.caseId,
                'reimbursementSum': caseStat.reimbursementSum,
                'reversalSum': caseStat.reversalSum,
            }
        })
        setCases(caseStats);
        setCasesLoaded(true);
    };

    const prepReversals = (reversalStats) => {
        reversalStats = Object.values(reversalStats);
        setReversals(reversalStats);
        setReversalsLoaded(true);
    };

    return (
        <Container maxWidth={false} className={classes.dashboardContainer}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper className={fullHeightPaper}>
                        <div className="text-center">
                            <CaseTable caseData={cases} casesLoaded={casesLoaded} title="Erfolgreiche Fälle im Erstattungszeitraum" />
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper className={fullHeightPaper}>
                        <div className="text-center">
                            <CaseTable caseData={reversals} casesLoaded={reversalsLoaded} title="Korrekturen von früheren Fällen"/>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
});
