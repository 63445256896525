import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";

function Tendency(props) {
    const {classes, current, before} = props;

    if (current / before < 0.80) {
        return (
            <FontAwesomeIcon icon={["far", "arrow-circle-down"]} className={classes.labelDanger}/>
        );
    } else if (current / before > 1.20) {
        return (
            <FontAwesomeIcon icon={["far", "arrow-circle-up"]} className={classes.labelSuccess}/>
        );
    } else {
        return (
            <FontAwesomeIcon icon={["far", "minus-circle"]}/>
        );
    }
}

export default withStyles(mitouStyles)(Tendency);
