import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import {createCustomerShipment, getCustomerShipments, updateCustomerShipment} from "../Service/CustomerShipmentService";
import {
    AsinLogisticData,
    CustomerShipment,
    CustomerShipmentFilter,
    CustomerShipmentFilterState,
    CustomerShipmentFormDialog as FormDialog,
    CustomerShipmentStates,
    CustomerShipmentTable,
    PlatformType,
    Title,
} from "@mitou/react-library";
import {CustomerShipmentFormClassses} from "@mitou/react-library/build/component/customerShipment/CustomerShipmentForm";
import {getByAsin} from "../Service/AsinLogisticDataService";

interface CustomerShipmentPageProps {
    classes: {
        dashboardPaper: string;
        dashboardFullHeight: string;
        flexContainer: string;
        dashboardContainer: string;
    } & CustomerShipmentFormClassses
}

function CustomerShipmentPage({classes}: CustomerShipmentPageProps) {
    const [states, setStates] = useState<CustomerShipmentStates[]>([]);
    const [priority, setPriority] = useState('');
    const [taskType, setTaskType] = useState('');
    const [shipmentId, setShipmentId] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [shipments, setShipments] = useState(null);
    const [selectedShipment, setSelectedShipment] = useState<CustomerShipment|null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const fullHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFullHeight);
    const applyFilter = (filter: CustomerShipmentFilterState) => {
        setStates(filter.states);
        setPriority(filter.priority);
        setTaskType(filter.taskType);
        setShipmentId(filter.shipmentId);
    };
    const onClickCreateShipment = () => {
        setSelectedShipment(null);
        setOpenDialog(true);
    };
    const onClickUpdateShipment = (shipment: CustomerShipment) => {
        setSelectedShipment(shipment);
        setOpenDialog(true);
    };
    const onCancelDialog = () => {
        setOpenDialog(false)
    };
    const onCreate = (data: CustomerShipment): Promise<void> => {
        return createCustomerShipment(data).then(() => {
            setOpenDialog(false);
            setRefresh(true);

            return Promise.resolve();
        });
    };
    const onUpdate = (data: CustomerShipment): Promise<void> => {
        return updateCustomerShipment(data.id, data).then(() => {
            setOpenDialog(false);
            setRefresh(true);

            return Promise.resolve();
        });
    };
    const loadLogisticData = (asin: string): Promise<AsinLogisticData | null> => {
        return getByAsin(asin);
    }

    useEffect(function () {
        setRefresh(false);
        getCustomerShipments(shipmentId, states, priority, taskType).then((data) => {
            setShipments(data);
        })
    }, [states, priority, taskType, refresh, shipmentId]);

    return (
        <Container maxWidth={false} className={classes.dashboardContainer}>
            <Paper className={fullHeightPaper}>
                <div hidden={openDialog}>
                    <Title>Fulfillment Sendungen</Title>
                    <CustomerShipmentFilter states={states} priority={priority} taskType={taskType}
                                            shipmentId={shipmentId} applyFilter={applyFilter}
                                            onClickCreateShipment={onClickCreateShipment}
                                            platform={PlatformType.PORTAL} />
                    <CustomerShipmentTable shipments={shipments} onUpdateShipment={onClickUpdateShipment}
                                           platform={PlatformType.PORTAL} />
                </div>
                {openDialog && (
                    <FormDialog shipment={selectedShipment} onCancel={onCancelDialog}
                                onCreate={onCreate} onUpdate={onUpdate} classes={classes}
                                platform={PlatformType.PORTAL} loadLogisticData={loadLogisticData}/>
                )}
            </Paper>
        </Container>
    );
}

export default withStyles(mitouStyles)(CustomerShipmentPage);
