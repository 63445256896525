import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import {mitouStyles} from "../Helpers/Styles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {AccountStatus, Advertising, Contact, Revenue, TaskTimeline, ToDos} from "../Component/Dashboard";
import UserService from "../Service/UserService";
import {getDashboardData} from "../Service/DashboardService";
import {useSelector} from "react-redux";
import News from "../Component/Dashboard/News";
import Calendar from "../Component/Dashboard/Calendar";
import {Loading} from "@mitou/react-library";


function DashboardPage({classes}) {
    const [data, setData] = useState(undefined);
    const [reload, setReload] = useState(true);
    const accountId = useSelector(state => state.account.accountId);
    const marketplace = useSelector(state => state.account.marketplace);
    const accountLoaded = useSelector(state => state.account.loaded);
    const fixedHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFixedHeight);
    const taskTimelinePaper = clsx(classes.dashboardPaper, classes.dashboardTaskTimelinePaper);
    const revenuePaper = clsx(fixedHeightPaper, classes.dashboardRevenueBackground);
    const accountStatusPaper = clsx(fixedHeightPaper, classes.dashboardAccountStatusGradient);
    const advertisingPaper = clsx(fixedHeightPaper, classes.dashboardAdvertisingGradient);

    useEffect(() => {
        let requestTimeout = null;
        if (reload === true) {
            setReload(false);
            return ;
        }

        if (accountId !== null && accountId !== undefined) {
            getDashboardData(accountId, marketplace)
                .then(data => {
                    setData(data);
                })
                .finally(() => {
                    clearInterval(requestTimeout)
                    setReload(false);
                    requestTimeout = setTimeout(() => setReload(true), 60000);
                });
        } else if (accountLoaded) {
            setData(null);
        }
        return () => clearInterval(requestTimeout);
    }, [accountId, marketplace, accountLoaded, reload])

    if (data === undefined) {
        return (
            <Container maxWidth={false} className={classes.dashboardContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.dashboardRelativeGridItem}>
                        <Paper className={fixedHeightPaper}>
                            <Loading/>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    return (
        <Container maxWidth={false} className={classes.dashboardContainer}>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.dashboardRelativeGridItem}>
                    <Paper className={taskTimelinePaper}>
                        <TaskTimeline />
                    </Paper>
                </Grid>
                {data !== null && (
                    <>
                        <Grid item xs={12} md={6} lg={4}>
                            <Paper className={revenuePaper}>
                                <Revenue data={data.salesData} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Paper className={accountStatusPaper}>
                                <AccountStatus data={data.accountHealth} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={5}>
                            <Paper className={advertisingPaper}>
                                <Advertising data={data.advertisingProfileStatistics} />
                            </Paper>
                        </Grid>
                    </>
                )}
                <Grid item xs={12} md={6} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <News />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <Calendar />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <ToDos />
                    </Paper>
                </Grid>
                { UserService.getContactPerson() &&
                <Grid item xs={12} md={6} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <Contact />
                    </Paper>
                </Grid>
                }
            </Grid>
        </Container>
    );
}

export default withStyles(mitouStyles)(DashboardPage);
